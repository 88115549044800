<template>
</template>

<script>
  export default {
  	name: 'TextAdventure',
    mounted() {
      if (!document.getElementById('puzzle')) {
	      let script = document.createElement('script');
	      script.setAttribute('src', 'build/bundle.js');
	      script.setAttribute('id', 'puzzle');
	      document.head.appendChild(script);
			}
			let app = document.getElementById('app');
	  	app.style.display = 'none';
    },
    destroyed() {
    	document.getElementsByTagName('main')[0].remove();
    	document.getElementById('puzzle').remove();
      let app = document.getElementById('app');
	  	app.style.display = 'flex';
    }
  }
</script>
<style>
	main {
	  background-color: rgba(4, 99, 7, 0.8);
	  backdrop-filter: blur(5px);
	  width: 66%;
	  border-radius: 0px 12px 12px 0px;
	  box-shadow: 5px 5px 5px #424242;
	  margin-left: -10px;
	  margin-top: 20px;
	  margin-bottom: 20px;
	  font-family: Avenir, Helvetica, Arial, sans-serif;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  text-align: left;
	  color: #ffffff;
	  display: flex;
	  flex-direction: column;
	  position: relative;
	  padding: 20px 42px;
	}

	.accent {
		color: #CD9B1D;
    font-weight: bold;
	}

	input, .pre-input {
		font-size: 18px;
	}

	input:focus-visible {
		outline-color: rgba(4, 99, 7, 0.8);
	}

	@media only screen and (max-width: 768px) {
	  main {
	    width: auto;
	    border-radius: 0;
	    box-shadow: none;
	  }
	}
</style>